.container {
  position: absolute;
  left: 4.45rem;
  top: 10.83%;
  width: 10.31rem;
  height: 87.5%;
  background: url("../../../static/images/mapBg.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 1.50rem;

  .back {
    position: absolute;
    bottom: 0.5rem;
    right: 0.87rem;
    color: #0EF2FF;
    cursor: pointer;
    >image{
      width: 1.07rem;
      height: 0.86rem;
      margin-bottom: 10px;
      vertical-align: top;
    }
  }

  .table {
    position: absolute;
    top: 0.4rem;
    left: 0;
    width: 100%;
    text-align: left;
    padding: 0 0.36rem;

    > table {
      width: 100%;

      > thead {
        font-size: 0.16rem;
        font-weight: bold;
        color: #ffffff;

        th {
          width: 25%;

          &:nth-child(1) {
            width: 30%;
          }

          &:nth-child(4) {
            width: 20%;
          }
        }
      }

      > tbody {
        > tr {
          td {
            text-align: left;

            > span:nth-child(1) {
              font-size: 0.45rem;
              background-image: -webkit-linear-gradient(top, #6FC7FF, #04F4F8);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              vertical-align: middle;
            }

            > span:nth-child(2) {
              font-size: 0.2rem;
              margin-left: 0.1rem;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              vertical-align: middle;
            }

            .add {
              background-image: -webkit-linear-gradient(top, #59FF5D, #00EF22);
            }

            .reduce {
              background-image: -webkit-linear-gradient(top, #FF4049, #FF000D);
            }
          }
        }
      }
    }

  }

  .legend {
    position: absolute;
    bottom: 1rem;
    left: 0.83rem;
    text-align: left;

    > div {
      color: #37BAC1;
      margin-bottom: 0.16rem;

      &:nth-child(1) {
        > span:nth-child(1) {
          background: #098C9F;
          border: 0.02rem solid #0DF2FF;
        }
      }

      &:nth-child(2) {
        > span:nth-child(1) {
          background: #014D64;
          border: 0.02rem solid #0DF2FF;
        }
      }

      > span:nth-child(1) {
        display: inline-block;
        width: 0.4rem;
        height: 0.2rem;
        border-radius: 0.04rem;
        margin-right: 0.1rem;
        vertical-align: middle;
      }

      > span:nth-child(2) {
        vertical-align: middle;
      }
    }
  }
}