.content {
  background: url('../../../static/images/thirdBg.png') no-repeat;
  position: absolute;
  right: 0.18rem;
  top: 71.67%;
  width: 4.08rem /* 408/100 */;
  height: 26.66%;
  background-size: 100% 100%;
  padding-top: 0.49rem;
}

.charts {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 0.2rem;
}

.chart {
  flex: 1;
  height: 100% !important;
}

.weight {
  position: absolute;
  top: 40%;
  left: 0;
  margin-top: -0.1rem;
  width: 100%;
  line-height: 0.2rem;
  display: flex;
  color: #ffffff;
  text-align: center;
  padding: 0 0.2rem;

  > div {
    flex: 1;
  }
}

.label {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  text-align: left;
  font-size: .12rem /* 12/100 */;
  padding-left: 0.37rem;

  div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: .04rem /* 10/100 */;

    .name {
      display: inline-block;
      width: 2.2rem /* 100/100 */;
    }

    .totalWeight {
      display: inline-block;
      width: 1rem /* 60/100 */
    }

    > div:nth-child(3) {
      display: none;
    }
  }
}

.pie {
  display: inline-block;
  width: .1rem; /* 10/100 */
  height: .1rem;
  border-radius: 50%;
  margin-right: .16rem /* 16/100 */;
}

@media screen and (max-width: 1300px) {
  .label {
    > div {
      display: inline-block;
    }

    .name {
      width: 2rem !important;
    }

    .totalWeight {
      width: 1.6rem !important;
    }

    div > div:nth-child(3) {
      display: none;
    }
  }
}