.content {
  padding-top: .49rem /* 10/100 */;
}

.title {
  position: absolute;
  top: .1rem;
  left: .17rem;
  width: 100%;
  background: url('../../static/images/titleBg.png') no-repeat;
  background-size: 100% 100%;
  height: .40rem /* 39/100 */;
  font-size: .20rem /* 22/100 */;
  line-height: .4rem /* 40/100 */;
  color: #fff;
  text-align: left;
  padding-left: .17rem /* 14/100 */;
}

.chart {
  width: 100%;
  height: 100% !important;
}

.unit {
  font-size: .14rem /* 14/100 */;
}
