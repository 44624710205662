.container {
  position: relative;
  width: 100vw;
  height: 9.9%;
  background: url("../../../static/images/headerBg.png") no-repeat;
  background-size: 100% 100%;

  .title {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -0.2rem;
    height: 0.4rem;
    text-align: center;
    width: 100%;

    > img:nth-child(1) {
      width: 1.54rem;
      height: 0.34rem;
      vertical-align: middle;
    }

    > img:nth-child(2) {
      width: 0.03rem;
      height: 0.39rem;
      margin-left: 0.24rem;
      margin-right: 0.24rem;
      vertical-align: middle;
    }

    > span {
      font-size: 0.34rem;
      line-height: 1;
      background-image: -webkit-linear-gradient(top, #FFFFFF, #5FD7F5);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      vertical-align: middle;
    }
  }

  .time {
    position: absolute;
    right: 0;
    top: 50%;
    width: 3.42rem;
    height: 0.28rem;
    line-height: 0.28rem;
    color: #ffffff;
    background: url("../../../static/images/time.png") no-repeat left center;
    background-size: 0.28rem 0.28rem;
    padding-left: 0.41rem;
    text-align: left;
    font-size: 0.22rem;
  }
}